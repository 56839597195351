import { createStore } from "@telia/b2x-state";
import type { MessageType, ToastMessage } from "../@types/types";

const initialState: { messages: MessageType[] } = { messages: [] };

const store = createStore("b2b-message-service", {
  initialState,
});

export const addNewToast = (
  id: string,
  status: ToastMessage["status"],
  title: string,
  body: string,
  action?: ToastMessage["action"]
) => {
  return addNewMessage({
    id,
    status,
    title,
    body,
    action,
    type: "toast",
  });
};

export const addNewMessage = (message: Omit<MessageType, "pageUrl">) => {
  const state = store.getState();

  store.setState({
    ...state,
    messages: [...(state?.messages ?? []), { ...message, pageUrl: window.location.href }],
  });
};

export const getAllMessages = (): MessageType[] => {
  const state = store.getState();

  return state?.messages ?? [];
};

export const deleteMessage = (id: string) => {
  const state = store.getState();

  const filteredMessages = state?.messages.filter((message) => message.id !== id);

  store.setState({
    ...state,
    messages: filteredMessages,
  });
};

export const deleteAllMessages = () => {
  store.setState({
    messages: [],
  });
};

export const subscribe = store.subscribe;

const handlePopstate = () => {
  const state = store.getState();

  const currentUrl = window.location.href;
  const filteredMessages = state?.messages.filter((message) => message.pageUrl === currentUrl);

  store.setState({
    ...state,
    messages: filteredMessages,
  });
};

// single-spa exports
export async function bootstrap(): Promise<void> {
  return Promise.resolve();
}

export async function mount(): Promise<void> {
  window.addEventListener("popstate", handlePopstate);

  return Promise.resolve();
}

export async function unmount(): Promise<void> {
  window.removeEventListener("popstate", handlePopstate);

  return Promise.resolve();
}
